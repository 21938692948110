import { ADJUSTBY } from '../config';

export default class Cactus {
  private ctx: CanvasRenderingContext2D;
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  private image: HTMLImageElement;
  private index: number;

  constructor(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    image: HTMLImageElement,
    index: number
  ) {
    this.ctx = ctx;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.image = image;
    this.index = index;
  }

  update(speed: number, gameSpeed: number, frameTimeDelta: number, scaleRatio: number): void {
    this.x -= speed * gameSpeed * frameTimeDelta * scaleRatio;
  }

  draw(): void {
    this.ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
  }

  // Check if DINO hit Cactus
  collideWith(sprite: { x: number; y: number; width: number; height: number }): boolean {
    const adjustBy = ADJUSTBY; // Space to detect HIT
    if (
      sprite.x < this.x + this.width / adjustBy &&
      sprite.x + sprite.width / adjustBy > this.x &&
      sprite.y < this.y + this.height / adjustBy &&
      sprite.height + sprite.y / adjustBy > this.y
    ) {
      return true;
    } else {
      return false;
    }
  }

  getIndex(): number {
    return this.index;
  }
}

import { useRef, useState, useEffect } from 'react';
import GameOver from '../components/GameOver';
import { GameContainer } from './dino.styled';
import { DinoGame } from './lib/Game';
import GameNotification from '../components/GameNotification';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useLandscapeMode } from '@/modules/games/among-us/src/hooks/useLandscapeMode';
import Instruction from '../components/Instruction';
import { ROUTES } from '@/modules/games/among-us/src/routes';
import React from 'react';
import { useAccountInfoContext } from '@/contexts/account-info.context';

export const Dino: React.FC = () => {
  const { refetchProfile } = useAccountInfoContext();

  const user = {
    id: '79'
  };
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showGameOver, setShowGameOver] = useState(false);
  const [showSwitchApp, setShowSwitchApp] = useState(false);
  const [showGameError, setShowGameError] = useState(false);
  const [gameSessionId, setGameSessionId] = useState(v4());
  const dinoGameRef = useRef<DinoGame | null>(null);
  const isLandscape = useLandscapeMode();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id) return;

    const canvas = canvasRef.current;
    if (!canvas) {
      console.warn('Canvas not found');
      return;
    }

    // Handle game error display
    const handleGameError = () => {
      console.log('Game Error');
      setShowGameError(true);
    };

    // Cleanup previous game instance if exists
    if (dinoGameRef.current) dinoGameRef.current.makeGameOver();

    // Initialize a new game instance
    dinoGameRef.current = new DinoGame(false, user.id, handleGameError, setShowGameOver);
    dinoGameRef.current.initialize(canvas);

    // Event handler to start/reset the game
    const handleStartGame = () => {
      if (dinoGameRef.current) {
        dinoGameRef.current.reset();
      }
    };

    // Add event listeners for touch and keyboard input
    canvas.addEventListener('click', handleStartGame, { once: true });
    canvas.addEventListener('touchstart', handleStartGame, { once: true });
    canvas.addEventListener('keydown', handleStartGame, { once: true });

    // Cleanup event listeners on unmount or dependencies change
    return () => {
      canvas.removeEventListener('click', handleStartGame);
      canvas.removeEventListener('touchstart', handleStartGame);
      canvas.removeEventListener('keydown', handleStartGame);
    };
  }, [user?.id, gameSessionId, isLandscape]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && !dinoGameRef.current?.isGameOver()) {
        dinoGameRef.current?.makeGameOver();
        // setShowGameOver(true);
        setShowSwitchApp(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (showGameOver) {
      refetchProfile();
      return;
    }
  }, [refetchProfile, showGameOver]);

  return (
    <>
      <GameContainer id='game' ref={canvasRef} />
      {!showGameError && showGameOver && (
        <GameOver
          visible={showGameOver}
          totalPoint={Math.round(dinoGameRef.current?.session?.endSessionResult?.totalPoints || 0)}
          onPlay={async () => {
            setShowGameOver(false);
            // await dinoGameRef.current?.session?.getAvailableSession();
            // dinoGameRef.current?.reset();
            setGameSessionId(v4());
          }}
        />
      )}

      {isLandscape && (
        <Instruction
          visible={true}
          isDisplayCheckbox={true}
          isDisplayImage={true}
          onPlayGame={() => {
            navigate(ROUTES.HOME);
          }}
          onClose={() => {
            navigate(ROUTES.HOME);
          }}
        />
      )}

      {showSwitchApp && (
        <GameNotification
          content=' You switched or minimized the app while playing, which caused the game to reset. Start again whenever you’re ready!'
          btnText="I'M READY"
          visible={showSwitchApp}
          onPlay={async () => {
            setShowSwitchApp(false);
            // await dinoGameRef.current?.session?.getAvailableSession();
            setGameSessionId(v4());
            navigate(ROUTES.HOME);
          }}
          onCancel={() => {
            navigate(ROUTES.HOME);
          }}
        />
      )}
      {showGameError && (
        <GameNotification
          content="It seems there was an issue with the game. Please check your network and try again when you're ready!"
          btnText="I'M READY"
          visible={showGameError}
          onPlay={async () => {
            setShowGameError(false);
            setShowGameOver(false);

            // try {
            //   await dinoGameRef.current?.session?.getAvailableSession();
            //   dinoGameRef.current?.reset();
            // } catch (error) {
            //   setShowGameError(true);
            // }
            navigate(ROUTES.HOME);
          }}
        />
      )}
    </>
  );
};

import { gameController } from '@/modules/games/among-us/src/controllers';
import { EndGameResp, SessionInfo, StartGameResp, TypeGame } from '@/modules/games/among-us/src/types/IGame';

export class Session {
  private sessionId: string | null = null;
  startGameAt: Date | null = null;
  stopGameAt: Date | null = null;
  lastTrackAt: Date | null = null;
  sessionInfo: SessionInfo | null = null;
  currenSession: StartGameResp | null = null;
  deltaTimeBetweenFrontendAndBackend: number = 0;
  isTracking = false;
  private trackingInterval: NodeJS.Timeout | null = null;
  endSessionResult: EndGameResp | null = null;

  constructor(
    private hasFailSession: () => void,
    private onEndSession: (result: EndGameResp) => void
  ) {
    this.getAvailableSession();
  }

  getAvailableSession = async () => {
    this.sessionInfo = await gameController.getAvailableSession();
  };

  startSession = async (userId: string) => {
    try {
      await gameController.initPowTracker(userId);
      const resp = await gameController.start({
        type: this.getCurrentSession()
      });
      this.startGameAt = new Date();
      this.lastTrackAt = this.startGameAt;
      this.stopGameAt = null;

      this.sessionId = resp.sessionId;
      this.currenSession = resp;
      this.endSessionResult = null;
      this.deltaTimeBetweenFrontendAndBackend = resp.respondTime - new Date().getTime();

      // Start tracking every 4 seconds
      if (this.trackingInterval) clearInterval(this.trackingInterval);
      this.trackingInterval = setInterval(this.trackSession, 4000);
    } catch (error) {
      console.log('startSession', error);
      this.failSession();
    }
  };

  endSession = async (stopAt?: Date) => {
    this.stopGameAt = stopAt || new Date();

    // Clear the tracking interval
    if (this.trackingInterval) {
      clearInterval(this.trackingInterval);
      this.trackingInterval = null;
    }

    await this.trackSession(this.stopGameAt);
    try {
      this.endSessionResult = await gameController.end(this.sessionId!);
      this.onEndSession(this.endSessionResult);
    } catch (error) {
      console.log('endSession', error);
      this.failSession();
    }
  };

  cancelSession = async () => {
    if (this.trackingInterval) {
      clearInterval(this.trackingInterval);
      this.trackingInterval = null;
    }
    this.sessionId = null;
  };

  trackSession = async (trackAt?: Date) => {
    if (this.lastTrackAt === null) return;
    if (this.isTracking) return;
    const now = trackAt || new Date();
    this.isTracking = true;

    try {
      await gameController.track(
        this.sessionId!,
        this.toServerTime(this.lastTrackAt).getTime(),
        this.toServerTime(now).getTime()
      );
    } catch (error) {
      console.log('trackSession', error);
      this.failSession();
    }
    this.lastTrackAt = now;
    this.isTracking = false;
  };

  private failSession = () => {
    this.hasFailSession();
    if (this.trackingInterval) clearInterval(this.trackingInterval);
  };

  getCurrent = () => {
    return new Date().getTime();
  };

  getDuration = () => {
    if (this.startGameAt === null) return 0;
    if (this.stopGameAt !== null) return this.stopGameAt.getTime() - this.startGameAt.getTime();

    return this.getCurrent() - this.startGameAt!.getTime();
  };

  public toServerTime = (time: Date) => {
    return new Date(time.getTime() - this.deltaTimeBetweenFrontendAndBackend);
  };

  getCurrentSessionInfo = () => {
    return this.sessionInfo;
  };

  getCurrentSession = (): TypeGame => {
    if (!this.sessionInfo) return 'daily';
    return this.sessionInfo.availableDailySession > 0 ? 'daily' : 'treat';
  };

  setStopGameAt = (time: Date) => {
    this.stopGameAt = time;
  };
}

import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { gameController, telegramController } from '../controllers';
import { TypeGame } from '@/modules/games/among-us/src/types/IGame';

export const gameKeys = {
  all: ['GAME_SESSION'] as const,
  platform: ['PLATFORM'] as const
};

export const useAvailableSession = () => {
  const query = useQuery({
    queryKey: gameKeys.all,
    queryFn: async () => gameController.getAvailableSession()
  });

  return query;
};

export const useGetPlatform = () => {
  const query = useQuery({
    queryKey: gameKeys.platform,
    queryFn: async () => telegramController.getPlatform()
  });

  return query;
};

export const useHistoryGame = ({ limit }: { limit: number }) => {
  const query = useInfiniteQuery({
    queryKey: [...gameKeys.all, limit],
    queryFn: async ({ pageParam = 1 }) => {
      return gameController.getHistoryGame({ page: pageParam, limit });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { current_page = 1, total_page = 1 } = lastPage.pagination || {};
      const hasData = current_page < total_page;

      if (!hasData) {
        return undefined;
      }

      if (current_page < total_page) {
        return current_page + 1;
      }

      return undefined;
    },
    select: (data) => {
      return data ? data.pages.flatMap((page) => page.items) : [];
    }
  });

  return query;
};

export const useStartGame = () => {
  const mutation = useMutation({
    mutationFn: async (type: TypeGame) => gameController.start({ type })
  });

  return mutation;
};

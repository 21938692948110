import IOSInstruction from '@/modules/games/among-us/src/assets/images/IOS.webp';
import AndroidIntruction from '@/modules/games/among-us/src/assets/images/Android.webp';
import { WrapModal } from './styled';
import { useState } from 'react';
import { useGetPlatform } from '@/modules/games/among-us/src/hooks/useGame';
import Modal from '../Modal';
import { Checkbox } from 'antd';
import React from 'react';
import Button from '@/components/button/button';

interface IProps {
  visible: boolean;
  isDisplayCheckbox?: boolean;
  isDisplayImage?: boolean;
  onClose: () => void;
  onPlayGame?: () => void;
}

export const DISABLED_INSTRUCTION = 'DISABLED_INSTRUCTION';

const Instruction = ({
  visible = false,
  onClose,
  onPlayGame,
  isDisplayCheckbox = true,
  isDisplayImage = true
}: IProps) => {
  const [checked, setChecked] = useState(false);
  const { data: platform } = useGetPlatform();

  return (
    <Modal open={visible} onCancel={onClose} className='modal-instruction'>
      <WrapModal>
        {isDisplayImage && (
          <div className='image-bg'>
            <img src={platform?.platform === 'android' ? AndroidIntruction : IOSInstruction} width={240} />
          </div>
        )}
        <span
          style={{
            textAlign: 'center'
          }}
        >
          {'Make sure to lock your phone rotation setting before playing the game'}
        </span>
        {isDisplayCheckbox && (
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked((e) => !e);
            }}
          >
            <span
              style={{
                color: '#fff'
              }}
            >
              Don&apos;t show this again.
            </span>
          </Checkbox>
        )}
        <Button
          onClick={() => {
            if (checked) {
              localStorage.setItem(DISABLED_INSTRUCTION, 'true');
            }
            onPlayGame?.();
          }}
          className='buy-button w-full'
        >
          {"I'M READY"}
        </Button>
      </WrapModal>
    </Modal>
  );
};

export default Instruction;

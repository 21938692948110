import { BaseController } from './BaseController';
import {
  EndGameResp,
  IResponseWithPagination,
  IUserDinoGameDB,
  SessionInfo,
  StartGameResp,
  TypeGame
} from '@/modules/games/among-us/src/types/IGame';
import { MAX_INT_256, PoWTracker } from './powTracker';
import { sleep } from '@/modules/games/among-us/src/utils/helpers';

export const ERROR_REJECTED = 'rejected';

const prefixEndpoint = '/api/v1/games/among-us';

export class GameController extends BaseController {
  powTracker: PoWTracker | null = null;

  async getAvailableSession(): Promise<SessionInfo> {
    return this._client.get(`${prefixEndpoint}/available-sessions`);
  }

  async getHistoryGame(params: { page: number; limit: number }): Promise<IResponseWithPagination<IUserDinoGameDB>> {
    return this._client.get(``, { params });
  }

  async start(payload: { type: TypeGame }): Promise<StartGameResp> {
    // const nonce = this.getNonce();
    // const serverNonce = nonce?.serverNonce || "";
    // const userNonce = nonce?.userNonce || "";
    return this._client.post(
      `${prefixEndpoint}/start`,
      { ...payload },
      {
        timeout: 4000
      }
    );
  }

  async track(sessionId: string, startTime: number, endTime: number): Promise<void> {
    const nonce = await this.getNonce();
    const serverNonce = nonce?.serverNonce || '';
    const userNonce = nonce?.userNonce || '';
    return this._client
      .put(
        `${prefixEndpoint}/tracking`,
        { sessionId, startTime, endTime, serverNonce, userNonce },
        {
          timeout: 4000
        }
      )
      .then((resp: any) => {
        console.log(resp);

        const newNonceList = resp?.userNonces;
        if (newNonceList?.length) {
          this.setNonces(newNonceList);
        }
        return resp;
      });
  }

  async end(sessionId: string): Promise<EndGameResp> {
    return this._client.post(
      `${prefixEndpoint}/end`,
      { sessionId },
      {
        timeout: 4000
      }
    );
  }

  async getTapStatus() {
    const res = await this._client.get(`${prefixEndpoint}/user-nonces`);
    return res as any;
  }

  async initPowTracker(userId: string) {
    const status = await this.getTapStatus();
    if (status?.userNonces?.length === 0) throw Error('No nonces available');

    if (this.powTracker) this.powTracker.stopEpoch();
    const powTracker = new PoWTracker(
      `${userId}`,
      status?.powDifficulty ? BigInt(`0x${status?.powDifficulty}`) : MAX_INT_256
    );
    powTracker?.setNonces(status?.userNonces);
    powTracker?.startNewEpoch();
    powTracker?.incrementTaps(10);
    this.powTracker = powTracker;
  }

  async setNonces(userNonces: string[]) {
    if (!this.powTracker) {
      throw Error(ERROR_REJECTED);
    }

    if (!userNonces?.length) {
      const status = await this.getTapStatus();
      this.powTracker?.setNonces(status?.userNonces);
    } else {
      this.powTracker?.setNonces(userNonces);
    }
  }

  // startNewEpoch(){
  //   if(!this.powTracker){
  //     throw Error(ERROR_REJECTED);
  //   }

  //   this.powTracker.startNewEpoch()
  // }

  // stopEpoch(){console.log('tam stop')
  //   if(!this.powTracker){
  //     throw Error(ERROR_REJECTED);
  //   }

  //   this.powTracker.stopEpoch()
  // }

  async getNonce(): Promise<{
    userNonce: string;
    serverNonce: string;
    userName: string;
    tapsCount: number;
  }> {
    if (!this.powTracker) {
      throw Error(ERROR_REJECTED);
    }

    let value = this.powTracker.getPoWValues();
    if (!value.userNonce) {
      console.log(`waiting for sufficient PoW ${new Date().getTime() / 1000}`);
      await sleep(400); // need to wait until pow can calculate the nonce
      this.powTracker.updateBestPoW();
    }
    value = this.powTracker.getPoWValues();
    return value;
  }
}

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import Home from '@/modules/home';
import Referral from '@/modules/referral';
import Quest from '@/modules/quests';
import Leaderboard from '@/modules/leaderboard';
import Boost from '@/modules/boost';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import CheckIn from '@/modules/check-in';
import { AmongUsPage } from '@/modules/games/among-us';
import { DinoPlayGame } from '@/modules/games/among-us/src/pages/dino';

const elements = [
  {
    Component: Home,
    path: '/',
    UnLogin: CheckIn
  },
  {
    Component: Referral,
    path: '/referral',
    UnLogin: CheckIn
  },
  {
    Component: Quest,
    path: '/quest',
    UnLogin: CheckIn
  },
  {
    Component: Leaderboard,
    path: '/leaderboard',
    UnLogin: CheckIn
  },
  {
    Component: Boost,
    path: '/boost',
    UnLogin: CheckIn
  },
  {
    Component: AmongUsPage,
    path: '/among-us',
    UnLogin: CheckIn
  },
  {
    Component: DinoPlayGame,
    path: '/among-us/playing',
    UnLogin: CheckIn
  }
];

export const RouterProvider = () => {
  const token = useSelector(selectToken);
  const { isCheckIn } = useAccountInfoContext();
  return (
    <BrowserRouter>
      <Routes>
        {elements.map(({ Component, path, UnLogin }, idx) => (
          <Route
            key={idx}
            element={
              <React.Suspense fallback={null}>{isCheckIn && token ? <Component /> : <UnLogin />}</React.Suspense>
            }
            path={path}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '../controllers/apiConfig';

export const useGetCurrentBalanceInGame = () => {
  return useQuery({
    queryKey: [useGetCurrentBalanceInGame.name],
    queryFn: async () => {
      const { data } = await axiosClient.post(`/v1/protected-api/user/balance`);
      return data || 0;
    },
    enabled: true
  });
};

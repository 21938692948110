import React from 'react';

import { useAvailableSession } from '../../../hooks/useGame';
import Countdown from 'react-countdown';
import moment from 'moment';

import styled from 'styled-components';

const Wrapper = styled.div`
  color: #f15b5b;
  text-align: center;

  .ant-statistic-content-value,
  span {
    color: #f15b5b;
  }
`;

const PlayCountdown = () => {
  const { refetch } = useAvailableSession();

  return (
    <Wrapper>
      You have run out of free plays. Please come back in{' '}
      <Countdown daysInHours date={moment.utc().startOf('day').add(1, 'day').valueOf()} onComplete={refetch} />
    </Wrapper>
  );
};
export default PlayCountdown;

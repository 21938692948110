import { preloadImage } from '@/modules/games/among-us/src/utils/preloaResource';
import {
  BACKGROUND_SRC,
  GAME_SPEED_START,
  GAME_WIDTH,
  GAME_HEIGHT,
  PLAYER_WIDTH,
  PLAYER_HEIGHT,
  MIN_JUMP_HEIGHT,
  MAX_JUMP_HEIGHT,
  GROUND_WIDTH,
  GROUND_HEIGHT,
  GROUND_AND_CACTUS_SPEED,
  CACTI_CONFIG,
  GAME_SPEED_INCREMENT
} from '../config';
import CactiController from './CactiController';
import Ground from './Ground';
import { Level } from './Level';
import Player from './Player';
import Score from './Score';
import { Session } from './Session';
import { Snowflake } from './Snowflake';

// Preload images
const BACKGROUND = preloadImage(BACKGROUND_SRC);

export type CactusImage = {
  image: HTMLImageElement;
  width: number;
  height: number;
};

export class DinoGame {
  private player: Player | null = null;
  private ground: Ground | null = null;
  private cactiController: CactiController | null = null;
  private score: Score | null = null;
  private level: Level | null = null;
  session: Session | undefined;
  private snowflakes: Snowflake[] = [];
  private scaleRatio: number = 1;
  private previousTime: number | null = 0;
  private gameSpeed: number = GAME_SPEED_START;
  private gameOver: boolean = false;
  private waitingToStart: boolean = true;
  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;

  constructor(
    private enableAdvanceGraphicSetting: boolean,
    private userId: string,
    private hasGameError: () => void,
    private setShowGameOver: (value: boolean) => void
  ) {}

  public initialize(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.waitingToStart = true;
    this.ctx = canvas.getContext('2d');
    if (!this.ctx) {
      console.warn('Canvas context not found');
      return;
    }
    this.startGameSetup();
  }

  public reset = async () => {
    try {
      this.resetGameState();
      this.session?.startSession(this.userId).then(() => {
        if (this.player) this.player.lastJumpAt = new Date().getTime();
      });
      requestAnimationFrame(this.gameLoop);
    } catch (error) {
      console.error('Error during reset:', error);
      this.gameOver = true;
      this.setShowGameOver(true);
    }
  };

  private startGameSetup() {
    this.calculateScaleRatio();
    this.setCanvasDimensions();
    this.initializeGameObjects();
    requestAnimationFrame(this.gameLoop);
  }

  private calculateScaleRatio() {
    this.scaleRatio = 1; // Adjust this as necessary for different screen sizes
  }

  private setCanvasDimensions() {
    if (!this.canvas || !this.ctx) return;
    this.canvas.width = GAME_WIDTH * this.scaleRatio;
    this.canvas.height = GAME_HEIGHT * this.scaleRatio;
  }

  private initializeGameObjects() {
    const ctx = this.ctx!;
    const canvas = this.canvas!;
    const playerDimensions = this.getPlayerDimensions();
    const groundDimensions = this.getGroundDimensions();

    this.player = new Player(ctx, ...playerDimensions, this.scaleRatio);
    this.ground = new Ground(ctx, ...groundDimensions, GROUND_AND_CACTUS_SPEED, this.scaleRatio);

    const cactiImages = this.loadCactiImages();
    this.session = new Session(
      () => {
        this.gameOver = true;
        this.hasGameError();
      },
      () => {
        this.score?.update();
        this.score?.setHighScore();
      }
    );

    this.level = new Level(this.session);
    this.cactiController = new CactiController(ctx, cactiImages, this.scaleRatio, GROUND_AND_CACTUS_SPEED, this.level);
    this.score = new Score(ctx, this.scaleRatio, this.session);

    this.snowflakes = this.createSnowflakes(ctx, canvas);
  }

  private resetGameState() {
    this.gameOver = false;
    this.waitingToStart = false;
    this.resetGameObjects();
    this.gameSpeed = GAME_SPEED_START;
  }

  private resetGameObjects() {
    this.ground?.reset();
    this.cactiController?.reset();
    this.score?.reset();
  }

  private gameLoop = (currentTime: number) => {
    if (!this.canvas || !this.ctx) return;

    if (this.previousTime === null) {
      this.previousTime = currentTime;
      requestAnimationFrame(this.gameLoop);
      return;
    }

    const frameTimeDelta = currentTime - this.previousTime;
    this.previousTime = currentTime;

    this.clearCanvas();

    if (!this.gameOver && !this.waitingToStart) {
      this.updateGameObjects(frameTimeDelta);
      // if (this.level?.getCurrentLevel() === 10) {
      //   this.checkCollisions();
      //   this.checkNoJump();
      // }
      this.checkCollisions();
      this.checkNoJump();
    }

    this.renderGameObjects();

    if (!this.waitingToStart && !this.gameOver && this.level?.isShowLevel()) {
      this.displayLevelText();
    }

    if (this.waitingToStart) {
      this.displayStartGameText();
    }

    requestAnimationFrame(this.gameLoop);
  };

  private updateGameObjects(frameTimeDelta: number) {
    this.ground?.update(this.gameSpeed, frameTimeDelta);
    this.cactiController?.update(this.gameSpeed, frameTimeDelta);
    this.player?.update(this.gameSpeed, frameTimeDelta);
    this.score?.update();
    this.increaseGameSpeed(frameTimeDelta);
  }

  private checkCollisions() {
    if (this.cactiController?.collideWith(this.player)) {
      const stopAt = new Date();
      this.session?.setStopGameAt(stopAt);
      this.score?.update();
      this.gameOver = true;
      this.session?.endSession(stopAt).then(() => {
        this.score?.update();
        this.setShowGameOver(true);
      });
      this.score?.setHighScore();
    }
  }

  private checkNoJump() {
    const lastJumpAt = this.player?.lastJumpAt;
    if (!lastJumpAt) return;
    if (new Date().getTime() - lastJumpAt < 15 * 1000) return;

    this.gameOver = true;
    this.hasGameError();
  }

  private renderGameObjects() {
    this.drawBackground();
    this.ground?.draw();
    this.cactiController?.draw();
    this.player?.draw();
    this.score?.draw();

    if (this.enableAdvanceGraphicSetting) {
      this.snowflakes.forEach((snowflake) => {
        snowflake.update(this.canvas!.width, this.canvas!.height);
        snowflake.draw();
      });
    }
  }

  private increaseGameSpeed(frameTimeDelta: number) {
    this.gameSpeed += frameTimeDelta * GAME_SPEED_INCREMENT;
  }

  private clearCanvas() {
    this.ctx!.fillStyle = 'white';
    this.ctx!.fillRect(0, 0, this.canvas!.width, this.canvas!.height);
  }

  private displayStartGameText() {
    this.drawText('Tap screen to start', 56, '#FFF4');
  }

  private displayLevelText() {
    this.drawText(`Level ${this.level?.getCurrentLevel()}`, 128, '#FFF4');
  }

  private drawText(text: string, fontSize: number, color: string) {
    this.ctx!.font = `${fontSize * this.scaleRatio}px "Oxanium"`;
    this.ctx!.fillStyle = color;
    const x = (this.canvas!.width - this.ctx!.measureText(text).width) / 2;
    const y = this.canvas!.height / 2;
    this.ctx!.imageSmoothingEnabled = false;
    this.ctx!.fillText(text, x - 42, y);
  }

  private drawBackground() {
    this.ctx!.drawImage(
      BACKGROUND,
      0,
      0,
      this.canvas!.width,
      this.canvas!.height - GROUND_HEIGHT * this.scaleRatio + 1
    );
  }

  private getPlayerDimensions(): [number, number, number, number] {
    return [
      PLAYER_WIDTH * this.scaleRatio,
      PLAYER_HEIGHT * this.scaleRatio,
      MIN_JUMP_HEIGHT * this.scaleRatio,
      MAX_JUMP_HEIGHT * this.scaleRatio
    ];
  }

  private getGroundDimensions(): [number, number] {
    return [GROUND_WIDTH * this.scaleRatio, GROUND_HEIGHT * this.scaleRatio];
  }

  private loadCactiImages(): CactusImage[] {
    return CACTI_CONFIG.map((cactus) => ({
      image: preloadImage(cactus.image),
      width: cactus.width * this.scaleRatio,
      height: cactus.height * this.scaleRatio
    }));
  }

  private createSnowflakes(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement): Snowflake[] {
    return Array.from({ length: 100 }, () => new Snowflake(ctx, canvas.width, canvas.height));
  }

  public getScore = () => {
    return this.score?.getScore() || 0;
  };

  public makeGameOver = () => {
    this.gameOver = true;
    this.waitingToStart = true;
    this.session?.cancelSession();
  };

  public isGameOver = () => {
    return this.waitingToStart || this.gameOver;
  };
}

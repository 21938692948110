import { UserRepository } from '@/repository/user.repository';
import WebApp from '@twa-dev/sdk';
import { setAccessToken } from '@/utilities';
import { useDispatch } from 'react-redux';
import { updateAccount } from '@/redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
export const useLoginWithTelegram = () => {
  const dispatch = useDispatch();
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const handleLogin = async (point?: string, referralCode?: string) => {
    setIsLoadingLogin(true);
    const tonOauthResponse = await UserRepository.loginWithTelegram(
      WebApp.initData ||
        'query_id=AAEATshbAgAAAABOyFsc1Rug&user=%7B%22id%22%3A5834821120%2C%22first_name%22%3A%22Giang%22%2C%22last_name%22%3A%22Nguyen%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2F6Avh5cBvVVfnlySD-i_wEcosvfg_w1J7S7m9-uJ7pDyX6qqhhskzX9L-SKBCeM88.svg%22%7D&auth_date=1737628967&signature=lrxoI0l3D4Sb7yPfcoyiqCTOQcV2ANScUYU7ip2ZESfcWKbmvEFrr2Ih6llVagkHpDBo6c2RBA-ZClDczlgXBg&hash=f38389ff0abc3888792630ae32299356bd875973bc284ea9e847e8685d68fc73',
      point,
      referralCode
    );
    if (tonOauthResponse?.token) {
      setAccessToken(tonOauthResponse?.token);
      dispatch(
        updateAccount({
          token: tonOauthResponse?.token,
          expiresIn: ''
        })
      );
    } else {
      toast.error('Something went wrong!');
    }
    setIsLoadingLogin(false);
  };

  return {
    handleLogin,
    isLoadingLogin
  };
};

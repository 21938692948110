import styled from "styled-components";

export const WrapModal = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 24px;

  .treat-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .label {
      color: var(--White, #fff);
      font-size: 12px;
      font-weight: 400;
      opacity: 0.5;
    }

    .treats {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 4px;
    }

    .item-logo-treats-section {
      width: 20px;
      height: 20px;
    }
  }

  .image-bg {
    width: 100%;
  }

  .title {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .section-premium {
    display: flex;
    padding: 14px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
  }

  .button-treats {
    display: flex;
    gap: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .item-logo {
      width: 24px;
      height: 24px;
    }
  }

  .label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .group-button {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    .button-close {
      width: 30%;
    }

    .button {
      width: 100%;
    }
  }

  .suggestion {
    color: #f1d65b;
    text-align: center;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
  }

  .error {
    color: #f15b5b;
    text-align: center;
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
  }
`;

import { GROUND_SRC } from '../config';

export default class Ground {
  private ctx: CanvasRenderingContext2D;
  private canvas: HTMLCanvasElement;
  private width: number;
  private height: number;
  private speed: number;
  private scaleRatio: number;
  private x: number;
  private y: number;
  private groundImage: HTMLImageElement;

  constructor(ctx: CanvasRenderingContext2D, width: number, height: number, speed: number, scaleRatio: number) {
    this.ctx = ctx;
    this.canvas = ctx.canvas;
    this.width = width;
    this.height = height;
    this.speed = speed;
    this.scaleRatio = scaleRatio;

    this.x = 0;
    this.y = this.canvas.height - this.height;

    this.groundImage = new Image();
    this.groundImage.src = GROUND_SRC;
  }

  update(gameSpeed: number, frameTimeDelta: number): void {
    // same move speed for all screen
    this.x -= gameSpeed * frameTimeDelta * this.speed * this.scaleRatio;
  }

  draw(): void {
    this.ctx.drawImage(this.groundImage, this.x, this.y, this.width, this.height);

    this.ctx.drawImage(this.groundImage, this.x + this.width - 1, this.y, this.width, this.height);

    this.ctx.drawImage(this.groundImage, this.x + 2 * this.width - 1, this.y, this.width, this.height);

    // infinity background
    if (this.x < -this.width) {
      this.x = 0;
    }
  }

  reset(): void {
    this.x = 0;
  }
}

import { ITelegramUser } from '@/modules/games/among-us/src/types/ITelegramUser';

export interface TelegramPlatform {
  platform: string;
  version: string;
}

export class TelegramController {
  getInitData(): Promise<string> {
    const tele = window.Telegram.WebApp;
    const initData = tele.initData;
    return Promise.resolve(initData);
  }

  getPlatform(): TelegramPlatform {
    return {
      platform: window.Telegram.WebApp.platform,
      version: window.Telegram.WebApp.version
    };
  }

  async getCurrentUser(): Promise<ITelegramUser> {
    const initData = await this.getInitData();
    const params = new URLSearchParams(initData);
    const userJson = decodeURIComponent(params.get('user') || '{}');
    const user = JSON.parse(userJson);
    return user;
  }
}

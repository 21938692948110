import { Session } from './Session';

let configs = [
  { startAt: 0, endAt: 10, minInterval: 1400, maxInterval: 3600 },
  { startAt: 10, endAt: 20, minInterval: 1200, maxInterval: 3200 },
  { startAt: 20, endAt: 30, minInterval: 1000, maxInterval: 2800 },
  { startAt: 30, endAt: 40, minInterval: 900, maxInterval: 2400 },
  { startAt: 40, endAt: 50, minInterval: 850, maxInterval: 2000 },
  { startAt: 50, endAt: 70, minInterval: 800, maxInterval: 1600 },
  { startAt: 70, endAt: 90, minInterval: 800, maxInterval: 1500 },
  { startAt: 90, endAt: 110, minInterval: 800, maxInterval: 1400 },
  { startAt: 110, endAt: 130, minInterval: 700, maxInterval: 1200 },
  { startAt: 130, endAt: 200, minInterval: 700, maxInterval: 1000 },
  { startAt: 200, endAt: 300, minInterval: 700, maxInterval: 900 },
  { startAt: 300, endAt: undefined, minInterval: 200, maxInterval: 210 }
];

const decreaseLevel = 1;
configs = configs.map((config) => ({
  ...config,
  startAt: config.startAt * decreaseLevel,
  endAt: config.endAt && config.endAt * decreaseLevel,
  minInterval: config.minInterval * decreaseLevel,
  maxInterval: config.maxInterval * decreaseLevel
}));

export class Level {
  constructor(private session: Session) {}

  getStartedDuration = () => {
    if (this.session.startGameAt === null) return 0;
    if (this.session.stopGameAt) return this.session.stopGameAt.getTime() - this.session.startGameAt.getTime();
    const duration = this.session.getCurrent() - this.session.startGameAt.getTime();
    return duration;
  };

  getCurrentLevel = () => {
    const duration = this.getStartedDuration() / 1000;
    if (duration < 0) {
      return 1;
    }

    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      if (duration >= config.startAt && (config.endAt === undefined || duration < config.endAt)) {
        return i + 1;
      }
    }
    return configs.length;
  };

  getCurrentLevelConfig = () => {
    const level = this.getCurrentLevel();
    return { ...configs[level - 1], level };
  };

  isShowLevel = () => {
    const now = new Date().getTime();
    const { startAt } = this.getCurrentLevelConfig();
    const startGameAt = this.session.startGameAt?.getTime();
    if (!startGameAt) return true;
    const durationAfterLevelUp = now - startGameAt - startAt * 1000;
    if (durationAfterLevelUp < 3000) return true;
    return false;
  };
}

import { Dino } from '@/modules/games/among-us/src/games/Dino';
import { useStartGame } from '@/modules/games/among-us/src/hooks/useGame';
import BgImage from '@/modules/games/among-us/src/assets/images/bg.png';
import styled from 'styled-components';
import React, { memo } from 'react';

const GameContainer = styled.div`
  height: var(--tg-viewport-stable-height);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${BgImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const DinoPlayGameComponent = () => {
  const { isPending } = useStartGame();

  return <GameContainer>{isPending ? <div>Loading...</div> : <Dino />}</GameContainer>;
};
export const DinoPlayGame = memo(DinoPlayGameComponent);

import styled from 'styled-components';

export const WrapModal = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 24px;

  & .body {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  & .footer {
    & .button {
      width: 100%;
    }
  }
`;

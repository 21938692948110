export class Snowflake {
  private ctx: CanvasRenderingContext2D;
  private x: number;
  private y: number;
  private radius: number;
  private speedX: number;
  private speedY: number;

  constructor(ctx: CanvasRenderingContext2D, canvasWidth: number, canvasHeight: number) {
    this.ctx = ctx;
    this.x = Math.random() * canvasWidth;
    this.y = Math.random() * canvasHeight;
    this.radius = Math.random() * 2 + 1; // Radius between 1 and 3
    this.speedX = Math.random() * 0.5 - 0.25; // Horizontal speed between -0.25 and 0.25
    this.speedY = Math.random() * 1 + 0.5; // Vertical speed between 0.5 and 1.5
  }

  update(canvasWidth: number, canvasHeight: number): void {
    this.x += this.speedX;
    this.y += this.speedY;

    // Reset snowflake when it goes out of bounds
    if (this.y > canvasHeight - 130) {
      this.x = Math.random() * canvasWidth;
      this.y = -this.radius;
    }

    if (this.x > canvasWidth || this.x < 0) {
      this.x = Math.random() * canvasWidth;
      this.y = -this.radius;
    }
  }

  draw(): void {
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    this.ctx.fillStyle = 'white';
    this.ctx.fill();
    this.ctx.closePath();
  }
}

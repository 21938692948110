import { Session } from './Session';

export default class Score {
  private score: number = 0;
  private HIGH_SCORE_KEY: string = 'highScore';
  private ctx: CanvasRenderingContext2D;
  private canvas: HTMLCanvasElement;
  private scaleRatio: number;
  private session: Session;
  private duration: number = 0;

  constructor(ctx: CanvasRenderingContext2D, scaleRatio: number, _session: Session) {
    this.ctx = ctx;
    this.canvas = ctx.canvas;
    this.scaleRatio = scaleRatio;
    this.session = _session;
  }

  update(): void {
    const rate = this.session.currenSession?.rate || 0.5;
    this.duration = (Math.round(this.session.getDuration() / 10) * 10) / 1000;
    if (this.session.endSessionResult) this.score = this.session.endSessionResult.totalPoints;
    else this.score = Math.ceil(this.session.getDuration() * rate);
  }

  reset(): void {
    this.score = 0;
  }

  setHighScore(): void {
    const highScore = Number(localStorage.getItem(this.HIGH_SCORE_KEY));
    if (this.score > highScore) {
      localStorage.setItem(this.HIGH_SCORE_KEY, Math.floor(this.score).toString());
    }
  }

  draw(): void {
    const highScore = Number(localStorage.getItem(this.HIGH_SCORE_KEY));
    const y = 65 * this.scaleRatio;

    const fontSize = 24 * this.scaleRatio;
    this.ctx.font = `${fontSize}px "Oxanium"`;
    this.ctx.fillStyle = '#FFF';

    const scorePadded = Math.floor(this.score).toLocaleString('en-US');
    const highScorePadded = highScore.toLocaleString('en-US');
    const highScoreText = `HIGH SCORE    ${highScorePadded}`;

    const scoreLength = this.ctx.measureText(scorePadded).width;
    const highScoreLength = this.ctx.measureText(highScoreText).width;

    const scoreX = (this.canvas.width - scoreLength - 70) * this.scaleRatio;
    const highScoreX = (scoreX - highScoreLength - 15) * this.scaleRatio;

    this.ctx.fillStyle = '#00E1B7';
    this.ctx.fillText(highScoreText, highScoreX - 100, y);

    this.ctx.fillStyle = '#FFF';
    this.ctx.fillText(this.duration.toString(), 35, y);
    if (this.session.getCurrentSession() == 'daily') {
      this.ctx.fillStyle = '#FFF';
      this.ctx.fillText('FREE PLAY', 120, y);
    } else {
      this.ctx.fillStyle = '#F1D65B';
      this.ctx.fillText('PREMIUM PLAY', 120, y);
    }

    this.ctx.fillStyle = '#FFF';
    this.ctx.fillText(`  |    ${scorePadded}`, scoreX - 100, y);
  }

  getScore(): number {
    return this.score;
  }
}

import styled from "styled-components";

export const WrapModal = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 24px;

  .image-bg {
    display: flex;
    justify-content: center;
  }
`;

import { GAME_SPEED_START, OFFSET_GROUND } from '../config.js';
import Cactus from './Cactus.js';
import { Level } from './Level.js';

export default class CactiController {
  // random number to create cactus

  private nextCactusInterval: number | null = null;
  private cacti: Cactus[] = [];

  private level: Level;
  private ctx: CanvasRenderingContext2D;
  private canvas: HTMLCanvasElement;
  private cactiImages: {
    width: number;
    height: number;
    image: HTMLImageElement;
  }[];
  private scaleRatio: number;
  private speed: number;

  constructor(
    ctx: CanvasRenderingContext2D,
    cactiImages: { width: number; height: number; image: HTMLImageElement }[],
    scaleRatio: number,
    speed: number,
    _level: Level
  ) {
    this.ctx = ctx;
    this.canvas = ctx.canvas;
    this.cactiImages = cactiImages;
    this.scaleRatio = scaleRatio;
    this.speed = speed;
    this.level = _level;
    this.setNextCactusTime(GAME_SPEED_START);
  }
  private setNextCactusTime(gameSpeed: number): void {
    const config = this.level.getCurrentLevelConfig();
    const num = this.getRandomNumber(config?.minInterval / gameSpeed, config?.maxInterval / gameSpeed);

    this.nextCactusInterval = num;
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private createCactus(): void {
    const availableImages = [...this.cactiImages]; // Copy all cactus images

    // Check if there's at least one cactus already created
    if (this.cacti.length > 0) {
      const lastCactusIndex = this.cacti[this.cacti.length - 1].getIndex();
      // Remove the last used cactus image from the available images
      availableImages.splice(lastCactusIndex, 1);
    }

    // Generate a random index from the available images
    const randomIndex = this.getRandomNumber(0, availableImages.length - 1);
    const cactusImage = availableImages[randomIndex];

    const x = this.canvas.width * 1.5;
    let y;

    // Use the randomIndex or you may need to adjust this logic if you require the actual index in the original array
    const originalIndex = this.cactiImages.indexOf(cactusImage);

    if (originalIndex === 4) {
      y = this.canvas.height - cactusImage.height / 2 / 0.9 - OFFSET_GROUND * this.scaleRatio;
    } else if (originalIndex === 3) {
      const randomHights = [10, 70, 160];
      const randomHeight = randomHights[Math.floor(Math.random() * randomHights.length)];
      y = (randomHeight + OFFSET_GROUND) * this.scaleRatio;
    } else if (originalIndex === 2) {
      const randomHights = [120];
      const randomHeight = randomHights[Math.floor(Math.random() * randomHights.length)];
      y = (randomHeight + OFFSET_GROUND) * this.scaleRatio;
    } else {
      y = this.canvas.height - cactusImage.height - OFFSET_GROUND * this.scaleRatio * 0.95;
    }

    const cactus = new Cactus(this.ctx, x, y, cactusImage.width, cactusImage.height, cactusImage.image, originalIndex);
    this.cacti.push(cactus);
  }

  update(gameSpeed: number, frameTimeDelta: number): void {
    if (this.nextCactusInterval !== null && this.nextCactusInterval <= 0) {
      this.createCactus();
      this.setNextCactusTime(gameSpeed);
    }
    if (this.nextCactusInterval !== null) {
      this.nextCactusInterval -= frameTimeDelta;
    }

    this.cacti.forEach((cactus) => {
      cactus.update(this.speed, gameSpeed, frameTimeDelta, this.scaleRatio);
    });

    // remove cactus that is behind the DINO
    this.cacti = this.cacti.filter((cactus) => cactus.x > -cactus.width);
  }

  draw(): void {
    this.cacti.forEach((cactus) => cactus.draw());
  }

  collideWith(sprite: any): boolean {
    return this.cacti.some((cactus) => cactus.collideWith(sprite));
  }

  reset(): void {
    this.cacti = [];
  }
}

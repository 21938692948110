import styled from 'styled-components';
import { Modal as AntModal, ModalProps } from 'antd';
import React from 'react';

const ModalStyled = styled(AntModal)`
  .ant-modal-content {
    padding: 24px;
    color: #fff;
    border-radius: 16px;
    border: 2px solid #c47ade;
    background: var(--Dark-purple-80, #170e22);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.05) inset;

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`;

const Modal = ({ children, ...props }: ModalProps) => {
  return (
    <ModalStyled
      width={400}
      footer={null}
      closeIcon={
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
          <path
            d='M19.5459 18.454C19.7572 18.6653 19.876 18.952 19.876 19.2509C19.876 19.5497 19.7572 19.8364 19.5459 20.0477C19.3346 20.2591 19.0479 20.3778 18.749 20.3778C18.4501 20.3778 18.1635 20.2591 17.9521 20.0477L12 14.0937L6.0459 20.0459C5.83455 20.2572 5.54791 20.3759 5.24902 20.3759C4.95014 20.3759 4.66349 20.2572 4.45215 20.0459C4.2408 19.8345 4.12207 19.5479 4.12207 19.249C4.12207 18.9501 4.2408 18.6635 4.45215 18.4521L10.4062 12.4999L4.45402 6.54586C4.24268 6.33451 4.12395 6.04787 4.12395 5.74898C4.12395 5.4501 4.24268 5.16345 4.45402 4.95211C4.66537 4.74076 4.95201 4.62203 5.2509 4.62203C5.54978 4.62203 5.83643 4.74076 6.04777 4.95211L12 10.9062L17.954 4.95117C18.1654 4.73983 18.452 4.62109 18.7509 4.62109C19.0498 4.62109 19.3364 4.73983 19.5478 4.95117C19.7591 5.16251 19.8778 5.44916 19.8778 5.74804C19.8778 6.04693 19.7591 6.33358 19.5478 6.54492L13.5937 12.4999L19.5459 18.454Z'
            fill='white'
          />
        </svg>
      }
      maskClosable={false}
      centered
      {...props}
    >
      {children}
    </ModalStyled>
  );
};

export default Modal;

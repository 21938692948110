import Button from '@/components/button/button';
import Modal from '../Modal';
import { WrapModal } from './styled';
import React from 'react';

interface IProps {
  content: string;
  btnText: string;
  visible: boolean;
  onPlay?: () => void;
  onCancel?: () => void;
}

export default function GameNotification({ content, btnText, visible, onPlay, onCancel }: IProps) {
  return (
    <Modal open={visible} onCancel={onCancel}>
      <WrapModal>
        <div className='body'>
          <span>{content}</span>
        </div>
        <div className='footer'>
          <Button className='button' onClick={onPlay}>
            {btnText}
          </Button>
        </div>
      </WrapModal>
    </Modal>
  );
}

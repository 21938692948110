import { ENVS } from '@/config/env.config';
import axios, { AxiosInstance } from 'axios';

export const axiosClient: AxiosInstance = axios.create({
  baseURL: ENVS.VITE_BASE_API,
  headers: {
    'telegram-user-id': 111,
    Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`
  }
});

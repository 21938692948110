export const GAME_SPEED_START = 0.7;
export const GAME_SPEED_INCREMENT = 0.00001;

export const GAME_WIDTH = 800;
export const GAME_HEIGHT = 450;

export const PLAYER_WIDTH = 48;
export const PLAYER_HEIGHT = 64;

export const MAX_JUMP_HEIGHT = 300;
export const MIN_JUMP_HEIGHT = 200;

export const GROUND_WIDTH = 722;
export const GROUND_HEIGHT = 129;

export const GROUND_AND_CACTUS_SPEED = 0.5;

import Cacti1 from '@/modules/games/among-us/src/assets/images/cactus_1.png';
import Cacti2 from '@/modules/games/among-us/src/assets/images/cactus_2.png';
import Cacti3 from '@/modules/games/among-us/src/assets/images/cactus_3.png';
import Cacti4 from '@/modules/games/among-us/src/assets/images/bird.png';
import Cacti5 from '@/modules/games/among-us/src/assets/images/ditchv2.png';
export const CACTI_CONFIG = [
  { width: 70, height: 76, image: Cacti1 },
  { width: 74, height: 41, image: Cacti2 },
  { width: 50, height: 35, image: Cacti3 },
  { width: 79, height: 62, image: Cacti4 },
  { width: 80, height: 52, image: Cacti5 }
];

export { default as BACKGROUND_SRC } from '@/modules/games/among-us/src/assets/images/full_back_v3.png';

//CactiController
export const CACTUS_MIN = 700; //CACTUS_INTERVAL_MIN
export const CACTUS_MAX = 1800; //CACTUS_INTERVAL_MAX

//Ground
export { default as GROUND_SRC } from '@/modules/games/among-us/src/assets/images/bottombackground.png';
export const OFFSET_GROUND = GROUND_HEIGHT * 0.88;

//Player
export const WALK_TIMER = 200; //WALK_ANIMATION_TIMER
export const PLAYER_JUMP_SPEED = 0.7;
export const PLAYER_GRAVITY = 0.4;

export { default as PLAYER_STAND_SRC } from '@/modules/games/among-us/src/assets/images/standing_still.png';
export { default as PLAYER_JUMP_SRC } from '@/modules/games/among-us/src/assets/images/jump.png';
export { default as PLAYER_RUN1_SRC } from '@/modules/games/among-us/src/assets/images/dino_run1.png';
export { default as PLAYER_RUN2_SRC } from '@/modules/games/among-us/src/assets/images/dino_run2.png';
export { default as PLAYER_RUN3_SRC } from '@/modules/games/among-us/src/assets/images/dino_run3.png';
export { default as PLAYER_RUN4_SRC } from '@/modules/games/among-us/src/assets/images/dino_run4.png';

//cactus
export const ADJUSTBY = 1.1;

import { useState, useEffect } from 'react';

export const useLandscapeMode = () => {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return window.Telegram.WebApp.isOrientationLocked === undefined
    ? isLandscape
    : !window.Telegram.WebApp.isOrientationLocked;
};

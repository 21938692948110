import { WrapModal } from './styled';
import { Divider } from 'antd';
import { PRICE_FOR_TURN } from '@/modules/games/among-us/src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/modules/games/among-us/src/routes';
import { useAvailableSession } from '@/modules/games/among-us/src/hooks/useGame';
import { useGetCurrentBalanceInGame } from '@/modules/games/among-us/src/hooks/useBalance';
import DiamondIcon from '@/assets/logo/gems.png';
import Modal from '../Modal';
import React, { useEffect } from 'react';
import Button from '@/components/button/button';
import PlayCountdown from '../PlayCountdown';

interface IProps {
  visible: boolean;
  totalPoint?: number | string;
  onPlay?: () => void;
}

const GameOver = ({ visible, totalPoint, onPlay }: IProps) => {
  const navigate = useNavigate();
  const { data: balanceInGame, isLoading: isLoadingBalance } = useGetCurrentBalanceInGame();
  const { data, isLoading, refetch } = useAvailableSession();
  const dailyTurn = data && !isLoading ? data?.availableDailySession : 0;
  const premiumTurn = data && !isLoading ? data?.availablePremiumSession : 0;
  const balanceTreats = balanceInGame && !isLoadingBalance ? balanceInGame : 0;

  useEffect(() => {
    refetch();
  }, [refetch, visible]);

  function playAgain() {
    onPlay?.();
  }

  const renderSection = () => {
    return dailyTurn;
  };

  return (
    <Modal open={visible} onCancel={() => navigate(ROUTES.HOME)}>
      <WrapModal>
        <span className='title'>{'Game Over'}</span>
        <div className='row'>
          <span className='label'>{'Your Scores'}</span>
          <div className='button-treats'>
            <img className='item-logo' src={DiamondIcon} alt={'logo'} width={24} />
            <div>{totalPoint}</div>
          </div>
        </div>

        <Divider
          style={{
            margin: '-12px 0px',
            borderColor: '#FFF',
            opacity: 0.2
          }}
        />
        <div className='section-premium row'>
          <span>Free Play</span>
          <span className=''>{renderSection()}</span>
        </div>

        {dailyTurn > 0 && (
          <div className='group-button'>
            <Button
              onClick={() => {
                navigate(ROUTES.HOME);
              }}
              variant={'secondary'}
              className='button-close'
            >
              {'Close'}
            </Button>

            <Button
              onClick={() => {
                if (balanceTreats < PRICE_FOR_TURN && !dailyTurn && !premiumTurn) {
                  console.log('click');

                  // navigate(ROUTES.TREATS);
                  return;
                }
                playAgain();
              }}
              disabled={isLoading}
              isLoading={isLoading}
              className='button'
            >
              PLAY AGAIN
            </Button>
          </div>
        )}
        {!dailyTurn && (
          <>
            <Button
              onClick={() => {
                navigate(ROUTES.HOME);
              }}
              variant={'secondary'}
              className='button-close w-full'
            >
              {'Close'}
            </Button>
            <PlayCountdown />
          </>
        )}
      </WrapModal>
    </Modal>
  );
};

export default GameOver;

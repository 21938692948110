import crypto from 'crypto-js';
import sha256 from 'crypto-js/sha256';

// Client-side: Computes the hash using server nonce, username, and a client-generated nonce
export function hash(currentServerNonce: string, userName: string, userNonce: string): string {
  const data = `${currentServerNonce}${userName}${userNonce}`;
  return sha256(data).toString();
}

export const MAX_INT_256 = BigInt('0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF');

// Client and Server-side: Calculates the maximum number of taps that the proof of work can support
export function calculateMaxTaps(hash: string, W: bigint): number {
  const hashValue: bigint = BigInt('0x' + hash);
  if (hashValue > MAX_INT_256) {
    throw new Error('Invalid hash');
  }
  if (W > MAX_INT_256) {
    throw new Error('Invalid W');
  }
  const diff = Number(BigInt(W) - hashValue);
  return diff > 0 ? diff : 0;
}

// Server-side: Generates a secure random nonce
export function generateNonce(length = 16): string {
  return crypto.lib.WordArray.random(length).toString();
}

export class PoWTracker {
  userName: string;
  W: bigint;
  bestHash: string;
  bestValue: bigint;
  bestUserNonce: string;
  noncePool: string[];
  isActive: boolean;
  intervalId: NodeJS.Timeout | null;
  workingNonce: string | null;
  tapsSinceLastBatch: number;
  waitingForSufficientPoW: Promise<unknown> | null;
  powSufficientResolve: ((value: unknown) => void) | null;
  powSufficientReject: ((reason?: any) => void) | null;

  constructor(userName: string, W: bigint) {
    this.userName = userName;
    this.W = W;
    this.noncePool = [];
    this.isActive = false;
    this.intervalId = null;
    this.tapsSinceLastBatch = 0;
    this.workingNonce = null;
    this.bestHash = '';
    this.bestValue = MAX_INT_256;
    this.bestUserNonce = '';
    this.waitingForSufficientPoW = null;
    this.powSufficientResolve = null;
    this.powSufficientReject = null;
  }

  setNonces(nonces: string[]): void {
    this.noncePool = nonces;
    if (this.noncePool.length && this.workingNonce && !this.noncePool.includes(this.workingNonce!)) {
      this.resetPoW(this.noncePool.pop()!);
    }
    // console.log("Nonces set:", nonces);
  }

  startNewEpoch(): void {
    this.stopEpoch(); // Ensure any existing epoch is stopped before starting a new one
    if (this.noncePool.length === 0) {
      throw new Error('No nonces available for a new epoch');
    }
    this.resetPoW(this.noncePool.pop()!);
    this.isActive = true;
    this.tapsSinceLastBatch = 0;
    this.computeBackgroundPoW();
    // console.log("New epoch started with nonce:", this.workingNonce);
  }

  resetPoW(workingNonce: string | null): void {
    this.workingNonce = workingNonce;
    this.bestHash = '';
    this.bestValue = MAX_INT_256;
    this.bestUserNonce = '';
  }

  updateBestPoW(): void {
    if (!this.isActive) return;

    if (!this.workingNonce) {
      throw new Error('Working nonce is null');
    }

    const userNonce = generateNonce();
    const newHash = hash(this.workingNonce!, this.userName, userNonce);
    const newValue = BigInt('0x' + newHash);

    if (newValue < this.bestValue) {
      this.bestHash = newHash;
      this.bestValue = newValue;
      this.bestUserNonce = userNonce;
      // console.log(`New best PoW found: ${newHash}`);
    }
  }

  computeBackgroundPoW(): void {
    if (this.intervalId) {
      throw new Error('Background PoW computation already running');
    }

    this.intervalId = setInterval(() => {
      if (this.isActive && this.tapsSinceLastBatch > 0 && !this.isPoWSufficient()) {
        this.updateBestPoW();
      }
    }, 200); // Compute new PoW every 200 milliseconds
  }

  getPoWValues(): {
    userNonce: string;
    serverNonce: string;
    userName: string;
    tapsCount: number;
  } {
    if (!this.workingNonce) {
      throw new Error('Working nonce is null');
    }
    return {
      userNonce: this.bestUserNonce,
      serverNonce: this.workingNonce!,
      userName: this.userName,
      tapsCount: this.tapsSinceLastBatch
    };
  }

  isPoWSufficient(): boolean {
    const powSufficient = calculateMaxTaps(this.bestValue.toString(16), this.W) >= this.tapsSinceLastBatch;
    if (powSufficient && this.powSufficientResolve) {
      this.powSufficientResolve(true);
    }
    return powSufficient;
  }

  stopEpoch(): void {
    this.isActive = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    if (!this.isPoWSufficient() && this.powSufficientReject) {
      this.powSufficientReject();
    }
  }

  incrementTaps(numTaps: number = 1): void {
    this.tapsSinceLastBatch += numTaps;
  }

  /**
   * FOR TESTING ONLY
   */
  async waitForSufficientPoW(): Promise<void> {
    if (!this.isActive) {
      throw new Error('PoW calculation is not active');
    }
    if (this.isPoWSufficient()) {
      return;
    }
    if (!this.waitingForSufficientPoW) {
      this.waitingForSufficientPoW = new Promise((powSufficientResolve, powSufficientReject) => {
        this.powSufficientResolve = powSufficientResolve;
        this.powSufficientReject = powSufficientReject;
      });
    }
    await this.waitingForSufficientPoW;
    this.waitingForSufficientPoW = null;
    this.powSufficientResolve = null;
    this.powSufficientReject = null;
  }
}

import { Layout } from '@/components/layout/layout';
import { memo } from 'react';
import React from 'react';
import HomePage from './src/pages/home';
import styled from 'styled-components';
import QuestBtn from '@/assets/quest/btn-bg.png';
export const Wrapper = styled.div`
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 16px;
  background: linear-gradient(180deg, #402957 0%, #10051c 100%);
  .quest-btn {
    background: url(${QuestBtn});
    background-size: 100% 100%;
  }
`;
const AmongUsComponent = () => {
  return (
    <Layout>
      <Wrapper className='app-container flex flex-col items-center relative gap-5 !pb-[150px]'>
        <HomePage />
      </Wrapper>
    </Layout>
  );
};
export const AmongUsPage = memo(AmongUsComponent);
